import { fetchJson, fetchOk } from "src/datacraft-util";
import { getSecurityAmender } from "src/modules/helpers/authentication-helper";
import { fetchZ, postBody } from "./service-helper";
import { z } from "zod";

const securityAmender = getSecurityAmender();

const batchSchema =  z.object({
  smsBatchId:z.number(),
  sentByUsername:z.string(),
  sentAt: z.coerce.date(),
  exampleSmsMessage: z.string()
});

const batchMessageSchema =  z.object({
  message:z.string(),
  replies:z.array(z.object({message:z.string(), replyAt:z.coerce.date()}))
});

const batchMessagesSchema = z.record(z.string(), z.array(batchMessageSchema)).transform((obj) => new Map(Object.entries(obj)));

export const smsService = {
    messageTemplates:async () => await fetchJson<{ template:string }[]>(`/api/sms/message-templates`, await securityAmender.AppendSecurityToRequest({})),
    send:async(payload:{ facility: string, measureCategory: number, messages:{ message:string, phoneNumber:string, facility:string }[]}) => await fetchOk(`/api/sms/send-sms`, await postBody(payload)),
    listSentBatches: async (measureCategory:number, facility:string) => await fetchZ(z.array(batchSchema),`/api/sms/list-batches/${measureCategory}/${facility}`, await securityAmender.AppendSecurityToRequest({})),
    getBatchMessages: async (measureCategory:number, facility:string, batchId:number) => await fetchZ(batchMessagesSchema,`/api/sms/get-batch-messages/${measureCategory}/${facility}/${batchId}`, await securityAmender.AppendSecurityToRequest({})),
}