import { fetchJson } from "src/datacraft-util";
import { getSecurityAmender, ISecurityAmender } from "src/modules/helpers/authentication-helper";
import { z } from "zod";


const securityAmender:ISecurityAmender = getSecurityAmender();

export const postBody = (data: any) => securityAmender.AppendSecurityToRequest({
  method: "POST",
  headers: {
    'Accept': 'application/json; charset=utf-8',
    'Content-Type': 'application/json;charset=UTF-8'
  },
  body: JSON.stringify(data)
});


export async function fetchZ<Z extends z.ZodTypeAny = z.ZodNever>(schema:Z, input: RequestInfo, init?: RequestInit) {
  return schema.parse(await fetchJson(input, init)) as z.infer<typeof schema> ;

}